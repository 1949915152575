import React from "react";
import "./PageLoader.scss";

import Logo from "../../images/Logo.svg";

function PageLoader() {
  return (
    <div className="loader">
      <div className="content">
        <img className="logo" src={Logo} alt="Beau Herron Dev logo" />
        <div className="loader-line"></div>
      </div>
    </div>
  );
}

export default PageLoader;
