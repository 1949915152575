import React from 'react'
import './Navbar.scss'

import Logo from '../../images/Logo.svg'
import Button from '../Button/Button'
import { graphql, StaticQuery } from 'gatsby'

function Navbar() {
  return (
    <nav className='navbar'>
      <a href='/'>
        <img className='logo' src={Logo} alt="Beau Herron Dev logo" />
      </a>
      <StaticQuery
        query={graphql`query {
            allFile(filter: {name: {eq: "Resume"}}) {
              edges {
                node {
                  publicURL
                  name
                }
              }
            }
          }
        `}
        render={data => (
          <Button path={data.allFile.edges[0].node.publicURL} text='Resume' />
        )}
      />
      {/* <Button path='resume' text='Resume' /> */}
    </nav>
  )
}

export default Navbar