import React, { useEffect, useState } from "react";
import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import About from "../components/About/About";
import Projects from "../components/Projects/Projects";

import { watchViewport, unwatchViewport, TornisUpdateValues } from "tornis";

import "./styles.scss";
import { graphql } from "gatsby";
import PageLoader from "../components/PageLoader/PageLoader";

const IndexPage = ({ data }: GraphQL.Query.Projects) => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, Math.round(Math.random() * 1000 + 750));

  const projects = data?.allMarkdownRemark.edges.map((p) => p.node);

  const xPrecision = 16; //Number of bands to divide screen width by.
  const yPrecision = 12; //Number of bands to divide screen width by.

  const [vpSize, setVPSize] = useState({ x: 1680, y: 723 });

  const updateValues = ({ scroll, size, mouse }: TornisUpdateValues) => {
    const rightContainer = document.querySelector(
      ".right-container"
    ) as HTMLElement | null;
    const lattice = document.querySelector(
      ".lattice-container"
    ) as HTMLElement | null;
    const skullEyes = document.querySelector(
      ".skull-eyes"
    ) as HTMLElement | null;
    const skull = document.querySelector(".skull") as HTMLElement | null;
    const scrollIndicator = document.querySelector(
      ".scroll-indicator"
    ) as HTMLElement | null;
    const subIcons = document.querySelectorAll(
      ".sub-icon"
    ) as NodeListOf<HTMLElement> | null;

    if (scroll.changed && rightContainer && scrollIndicator) {
      rightContainer.style.transform = `translateY(${scroll.top * -0.7}px)`;
      scrollIndicator.style.height = `${500 - scroll.top / 3}px`;
    }

    if (mouse.changed && skullEyes && skull && lattice && subIcons) {
      const xBandWidth = vpSize.x / (xPrecision + 1);
      const yBandWidth = vpSize.y / (yPrecision + 1);
      const eyeXDelta = Math.floor(mouse.x / xBandWidth - xPrecision / 2);
      const eyeYDelta = Math.floor(mouse.y / yBandWidth - xPrecision / 2);

      const maxVal = ((2 * vpSize.x) / 3) * (vpSize.y / 2);
      const xPosRel = mouse.x - (2 * vpSize.x) / 3;
      const yPosRel = mouse.y - vpSize.y / 2;

      const latXPos = mouse.x - vpSize.x / 2;
      const latYPos = mouse.y - vpSize.y / 2;
      const latXRatio = latXPos / (vpSize.x / 2);
      const latYRatio = latYPos / (vpSize.y / 2);

      skullEyes.style.transform = `translate3d(${eyeXDelta}px, ${eyeYDelta}px, 0) rotate(${
        (5 * xPosRel * yPosRel) / maxVal
      }deg)`;
      skull.style.transform = `translate3d(${eyeXDelta / 4}px, ${
        eyeYDelta / 2
      }px, 0) rotate(${(2 * xPosRel * yPosRel) / maxVal}deg)`;
      lattice.style.transform = `translate3d(${-10 * latXRatio}px, ${
        -10 * latYRatio
      }px, 0)`;
      subIcons.forEach((subIcon) => {
        subIcon.style.transform = `translate3d(${-15 * latXRatio}px, ${
          -15 * latYRatio
        }px, 0)`;
      });
    }

    if (size.changed) {
      const tmp = {
        x: size.x,
        y: size.y,
      };

      setVPSize(tmp);
    }
  };

  useEffect(() => {
    watchViewport(updateValues);

    return () => {
      unwatchViewport(updateValues);
    };
  }, []);

  return (
    <>
      {loading && <PageLoader />}
      {!loading && (
        <>
          <Navbar />
          <Hero />
          <About />
          <Projects List={projects} />
        </>
      )}
    </>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>Beau Herron | Developer</title>
    <meta
      name="Beau Herron's personal portfolio."
      content="Beau Herron | Developer"
    />
  </>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            tags
            icon {
              publicURL
            }
            subIcon {
              publicURL
            }
          }
        }
      }
    }
  }
`;
