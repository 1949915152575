import { graphql, StaticQuery } from "gatsby";
import React from "react";

import Bar from "../Bar/Bar";
import ChatApp from "./ChatApp";
import "./Projects.scss";
import Websites from "./Websites";

export default function Projects(props: { List: GraphQL.Model.Project[] }) {
  return (
    <div className="projects padding">
      <div className="heading">
        <div className="left-container">
          <h1>
            <span className="text">Recent Projects </span>
            <span className="dot" style={{ color: "#FFF3CC" }}>
              .
            </span>
          </h1>
          <p>
            Here are some recent projects that I've worked on. Please visit the
            individual project pages to read more about them.
          </p>
        </div>
      </div>
      {props.List.map((project) => {
        return (
          <Bar
            title={project.frontmatter.title}
            description1={project.excerpt}
            description2={project.frontmatter.description2}
            tags={project.frontmatter.tags}
            iconPath={project.frontmatter.icon.publicURL}
            iconSubsetPath={project.frontmatter.subIcon.publicURL}
            pagePath={`/projects${project.frontmatter.slug}`}
          />
        );
      })}
      <ChatApp />
      <Websites />
    </div>
  );
}
