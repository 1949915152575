import React from 'react'
import './Button.scss'

// import PDF from '../../images/Resume.pdf'

type ButtonProps = {
  path: string;
  text: string;
  type?: string;
  color?: string;
};

function Button({ path, text, type = 'basic', color = 'white' }: ButtonProps) {
  if (path === 'mailto') {
    path = `mailto:hello@beauherrondev.com?subject=💻 Hey Beau, Let's work together!`
  }

  return (
    <a href={path} target={text === 'Resume' ? '_blank' : ''}
       rel={text === 'Resume' ? 'noreferrer' : ''} className="btn" style={{ backgroundColor: color }}>
      <div className='btn-inner'>
        <span className='btn-text' data-text={text}>
          {text}
        </span>
      </div>
    </a>
  )
}

export default Button