import React from "react";
import "./Hero.scss";

import Name from "../../images/Hero-name.svg";
import Skull from "../../images/Skull.svg";
import Eyes from "../../images/SkullEyes.svg";

function Hero() {
  return (
    <div className="hero padding">
      <div className="left-container">
        <img className="main-image" src={Name} alt="Beau Herron" />
        <p className="hero-text">
          Full stack developer currently based in Denver, Colorado.
        </p>
        <div className="scroll-indicator">
          <span className="scroll-text">Scroll</span>
        </div>
      </div>
      <div className="right-container">
        <div className="lattice-container">
          <div className="lattice"></div>
        </div>
        <div className="skull-container">
          <img className="skull" src={Skull} alt="skull" />
          <img className="skull-eyes" src={Eyes} alt="eyes" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
