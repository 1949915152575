import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Bar from "../Bar/Bar";

function ChatApp() {
  const chatProject = {
    title: "Chat Application",
    description1: `The classic chat application with a few twists. This project was
      built as a playground for features that I would eventually add to
      a similar chat component used within an application at work.`,
    description2: `Utilizing Ionic and Angular, my initial plan was to build an
      iMessage clone and add any unique features after the design was
      complete. This was made very simple using Ionic components such as
      ion-toolbar, ion-grid, and ion-list. This wasn't the first time
      that I had used Ionic components but I was suprised at how perfect
      the stock components seemed to be made for exaclty what I was
      doing. I have included mock data within the project to initialize
      a few message threads as well as a simple random-response
      generator. Feel free to message some of the contacts and see if
      they respond!`,
    tags: ["Playground", "Ionic", "Angular"],
    iconPath: "/static/chat-icon.svg",
    iconSubsetPath: "/static/chat-icon-sub.svg",
    pagePath: "/chat",
  };

  return (
    <StaticQuery
      query={graphql`
        query ChatImageQuery {
          allFile(filter: { name: { glob: "chat*" } }, sort: { fields: name }) {
            edges {
              node {
                publicURL
                name
              }
            }
          }
        }
      `}
      render={(data) =>
        data.allFile.edges.length > 1 && (
          <Bar
            title={chatProject.title}
            description1={chatProject.description1}
            description2={chatProject.description2}
            tags={chatProject.tags}
            iconPath={data.allFile.edges[0].node.publicURL}
            iconSubsetPath={data.allFile.edges[1].node.publicURL}
            pagePath={`/projects${chatProject.pagePath}`}
          />
        )
      }
    />
  );
}

export default ChatApp;
