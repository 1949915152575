import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Bar from "../Bar/Bar";

function Websites() {
  const staticSites = {
    title: "Websites",
    description1: `Since 2015, I have been making and designing websites for various reasons. Some of these have seen better days, and some are still wonderful. This page serves as a compilation of some of my favorite ones throughout the years. All of them were excellent opportunities to learn something new that I hadn’t done before. Here are a few I would like to briefly highlight!`,
    tags: ["Side Project", "HTML/CSS", "React", "JavaScript"],
    iconPath: "/static/chat-icon.svg",
    iconSubsetPath: "/static/chat-icon-sub.svg",
    pagePath: "/static-sites",
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteImageQuery {
          allFile(
            filter: { name: { glob: "sites*" } }
            sort: { fields: name }
          ) {
            edges {
              node {
                publicURL
                name
              }
            }
          }
        }
      `}
      render={(data) =>
        data.allFile.edges.length > 1 && (
          <Bar
            title={staticSites.title}
            description1={staticSites.description1}
            tags={staticSites.tags}
            iconPath={data.allFile.edges[0].node.publicURL}
            iconSubsetPath={data.allFile.edges[1].node.publicURL}
            pagePath={`/projects${staticSites.pagePath}`}
          />
        )
      }
    />
  );
}

export default Websites;
