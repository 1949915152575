import React, { useState } from "react";
import Button from "../Button/Button";
import "./Bar.scss";

export interface Project {
  title: string;
  description1: string;
  description2?: string;
  tags: string[];
  iconPath: string;
  iconSubsetPath: string;
  pagePath: string;
}

function Bar(project: Project) {
  const borderColor = "#FFCBE5";
  const [large, setLarge] = useState(false);

  return (
    <div
      className="project-bar"
      onClick={() => setLarge(!large)}
      style={{
        height: large ? "500px" : "200px",
        borderLeftColor: large ? borderColor : "white",
      }}
    >
      <div className="inner-image">
        <div className="img-container">
          <img className="main-icon" src={project.iconPath} />
          <img className="sub-icon" src={project.iconSubsetPath} />
        </div>
      </div>
      <div className="inner-bar" style={{ height: large ? "450px" : "80px" }}>
        <div className="title">
          <h2>{project.title}</h2>
          <div className="tags">
            {project.tags &&
              project.tags.map((tag) => <div className="tag">{tag}</div>)}
          </div>
        </div>
        <div
          className="description"
          style={{
            opacity: large ? "1" : "0",
            marginBottom: large ? "3rem" : "0",
          }}
        >
          <p>{project.description1}</p>
          {project.description2 && <p>{project.description2}</p>}
        </div>
        <div
          className="button-container"
          style={{ opacity: large ? "1" : "0" }}
        >
          <Button path={project.pagePath} text="Read More" />
        </div>
      </div>
    </div>
  );
}

export default Bar;
