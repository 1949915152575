import React from "react";
import Button from "../Button/Button";
import "./About.scss";

import Portrait from "../../images/Portrait.jpg";

function About() {
  return (
    <div className="about padding">
      <div className="left-about-container">
        <h1>
          <span className="text">About Me</span>
          <span className="dot" style={{ color: "#DBEEFD" }}>
            .
          </span>
        </h1>
        <p>
          I am a full stack developer and lifelong learner. As a former
          professional musician and engineering student, I fully embrace the
          challenge of melding art with technology. I enjoy creating minimal
          designs with simple coding solutions.{" "}
        </p>
        <Button path="mailto" color="#FFCBE5" text="Let's work together" />
      </div>
      <div className="right-about-container">
        <div className="image-container">
          <img src={Portrait} alt="Beau Herron portrait" />
        </div>
      </div>
    </div>
  );
}

export default About;
